import * as React from "react"

const Process = ({process}) => {
  return (
  <div className='process p40'>
    {process?.map((single, index) => { 
      return (
        <div className='single-process mb40' key={index}>
          <p className='h1 regular m0'>0{index+1}. {single.title}</p>
          <div className='content max-900 mt20 uppercase'>
            {single.content}
          </div>
        </div>
      )
    })}
  </div>
  )
}

export default Process
