import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import scrollTo from "../utils/scrollTo"

const ImageButton = ({image}) => (
  <div className='p40 flex m-wrap'>    
    <div className='button-wrapper m-100 m-mb40'>  
      <p className='button  m0' role='presentation' onClick={() => scrollTo('#form')}>Contact</p>
    </div>
    <div className='max-400 m-mwa mla w-100'>
      <div className='ratio-2-1 bg-grey pos-rel'>
        <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt='Sell' />
      </div>
    </div>
  </div>
)

export default ImageButton
 