import React, { useEffect } from "react"

const Scroll = () => {
  useEffect(() => {
    var height = window.innerHeight - document.querySelector('.masthead').offsetHeight -20;
    var timer = setInterval(function () {
      if(window.pageYOffset > height && document.querySelector('main').classList.contains('homepage')) {
        document.querySelector('.masthead')?.classList.add('dark')
      } else {
        document.querySelector('.masthead')?.classList.remove('dark')
      }
    }, 300);

    return () => {
      clearInterval(timer);
      document.querySelector('.masthead').classList.add('dark')
    }
  },[])
  return (
    <></>
  )
}

export default Scroll
