import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({image}) => (
  <div className='hero--banner bg-grey'>      
    <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt='Sell' />
  </div>
)

export default Image
 