import * as React from "react"

const Fees = ({content, table}) => {
  return (
  <div className='fees white p40 flex m-wrap'>
    <div className='w-50 m-100 m-mb40'>
      <div className='uppercase white-space max-350 m-mwa'>
        {content}
      </div>
    </div>
    <div className='w-70 m-100'>
      <div className='flex b1 uppercase strong text-center'>
        <div className='w-50 p20 br1'>
          Car value
        </div>
        <div className='w-50 p20'>
          Fee Structure
        </div>
      </div>
      {table?.map((single, index) => { 
      return (
        <div key={index} className='flex b1 bt0 uppercase strong small text-center'>
          <div className='w-50 p20 br1 flex align-center justify-center'>
            {single.carValue}
          </div>
          <div className='w-50 p20'>
            {single.feeStructure}
          </div>
        </div>
      )
    })}
    <div className='m0 small uppercase strong mt20 text-right'>Prices Exclude GST*</div>
    </div>
  </div>
  )
}

export default Fees
