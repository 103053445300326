import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import ImageButton from "../components/imageButton"
import Process from "../components/process"
import Spacer from "../components/spacer"
import Scroll from "../components/scroll"
import Title from "../components/title"
import Image from "../components/image"
import Form from "../components/form"
import Fees from "../components/fees"
import Seo from "../components/seo"
import { graphql } from "gatsby" 

const SellPage = ({data}) => {
  var page = data.page
  return (
  <Layout bodyClass='homepage'>
    <Scroll />
    <Image image={page.heroImage} /> 
    <Title title={page.title}/>
    <Introduction content={page.content} />
    <ImageButton image={page.introductionImage} />
    <Spacer id='process' />
    <Title title='Process'/>
    <Process process={page.process} />
    <Spacer />
    <div className='bg-dark-grey pt40 pb40'>
      <Title className='white' title='Fees'/>
      <Fees content={page.feeContent} table={page.feeTable} />
    </div>
    <div className='bg-slate pt40'>
      <Title className='white' title={page.formTitle}/>
      <Spacer id='form' />
      <Form/>
      <Spacer />
    </div>
  </Layout>
)
}

export const Head = () => <Seo title="Sell" />

export default SellPage

export const query = graphql`
  query SellPage {
    page:datoCmsSell {
      title
      formTitle
      content
      heroImage {
        gatsbyImageData
      }
      introductionImage {
        gatsbyImageData
      }
      process {
        title 
        content
      }
      feeContent
      feeTable {
        carValue
        feeStructure
      }
    }
    news:allDatoCmsNew(limit: 1) {
      edges {
        node {
          link
          title
          readTime
          featureImage {
            gatsbyImageData
          }
          meta {
            publishedAt(formatString: "D MMMM YYYY")
          }
        }
      }
    }
  }
`